import React from 'react';
import './style.css';
import HeroImage from '../../assets/Photos/HeroImage.png';

const HeroSection = () => {
  return (
    <section className="hero-section flex flex-col md:flex-row mt-8">
      <div className="hero-content">
        <h1 className="font-medium text-lg md:text-5xl md:leading-relaxed hero-title">
          India's first integrated and early-stage "airborne" company with a
          global footprint.
        </h1>
      </div>
      <div>
        {/* Carousel component or images */}
        <img
          loading="lazy"
          src={HeroImage}
          alt="Exploration Aircraft"
          height={18}
        />
      </div>
    </section>
  );
};

export default HeroSection;
